<template>
  <div>
    <app-simple-top />
    <v-main>
      <transition
        name="fade"
        mode="out-in"
      >
        <router-view v-slot="{ Component }">
          <component :is="Component" />
        </router-view>
      </transition>
    </v-main>
  </div>
</template>

<script>
import AppSimpleTop from '@/components/shared/SimpleTopNavigation';

export default {
  name: 'SimpleTemplate',
  components: {
    AppSimpleTop,
  },
};
</script>
